@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::selection {
  color: white;
  background-color: rgb(59, 130, 246);
}

@layer base {
  :root {

    --background: 0 0% 94%;
    --foreground: 222.2 84% 4.9%;
    
    /* backgrounds of elements */
    --surface: 0 0% 100%;

    --input-border-opacity: 0.1; 

    --border-rgb: 0 0 0;
    --border-opacity: 0.2;
  }

  [data-theme="dark"] {
    --background: 214 25% 11%;
    --foreground: 210 40% 98%;

    /* backgrounds of elements */
    --surface: 210 20% 16%;

    --input-border-opacity: 0.1; 

    --border-rgb: 255 255 255;
    --border-opacity: 0.1;
  }

  body {
    @apply bg-[hsl(var(--background))] text-[hsl(var(--foreground))];
  }
}

@layer utilities {
  .border-modes {
    @apply border;
    border-color: rgb(var(--border-rgb) / var(--border-opacity));
  }
  .border-r-modes {
    @apply border-r;
    border-color: rgb(var(--border-rgb) / var(--border-opacity));
  }
  .border-l-modes {
    @apply border-l;
    border-color: rgb(var(--border-rgb) / var(--border-opacity));
  }
  .border-t-modes {
    @apply border-t;
    border-color: rgb(var(--border-rgb) / var(--border-opacity));
  }
  .border-b-modes {
    @apply border-b;
    border-color: rgb(var(--border-rgb) / var(--border-opacity));
  }
  .input-border {
    @apply border;
    border-color: rgb(var(--border-rgb) / var(--input-border-opacity));
  }

    @keyframes growIn {
      from {
        @apply scale-y-0;
      }
      to {
        @apply scale-y-100;
      }
    }

    @keyframes zoomIn {
      from {
        @apply opacity-0 scale-50;
      }
      to {
        @apply opacity-100 scale-100;
      }
    }

    @keyframes fadeIn {
      from {
        @apply opacity-0;
      }
      to {
        @apply opacity-100;
      }
    }

    .growIn {
      animation: growIn 300ms ease-in-out forwards;
    }
    .fadeIn {
      animation: fadeIn 150ms ease-in-out forwards;
    }
    .zoomIn {
      animation: zoomIn 200ms ease-in-out forwards;
    }
}

@layer components {
  .inner-shadow {
    box-shadow: inset 0 0 1.4rem rgba(0,0,0,0.3);
  }
}